import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { inject } from '@angular/core';
import { CurrentUserService } from '../services/current-user.service';

export const redirectToManagerOrContractor: CanActivateFn = async (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const router = inject(Router);
  const user = inject(CurrentUserService);

  const route = user.isManagerLike ? 'manager' : 'contractor';
  await router.navigate(['webapp', 'projects', route]);
  return true;
};

import { inject, Injectable } from '@angular/core';
import { AppState } from '../app-state';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { projectActions } from './projects.actions';
import {
  catchError,
  exhaustMap,
  filter,
  map,
  repeat,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import { ProjectApiService } from '../../services/project-api.service';
import { getAllProjects, getProjectStatuses, getSimpleProjects } from './projects.selectors';
import { Observable, of } from 'rxjs';
import {
  IProjectFilters,
  Project,
  ProjectStatusKey,
} from '../../pages/webapp/projects/projects.interface';
import { IProjectStatus } from '../rollups/rollups.interface';
import { NotificationsService } from '../../services/notifications.service';

@Injectable()
export class ProjectsEffects {
  private store = inject(Store<AppState>);
  private actions = inject(Actions);
  private projectsApi = inject(ProjectApiService);
  private notif = inject(NotificationsService);

  // it doesn't load if there are projects
  loadProjects$ = createEffect(() =>
    this.actions.pipe(
      ofType(projectActions.loadProjects),
      withLatestFrom(this.store.select(getAllProjects)),
      switchMap(([_, projects]) => {
        if (!projects || projects.length === 0) {
          // it doesn't load if there are projects
          return this.projectsApi.getProjects();
        }
        return of(null);
      }),
      map((projects) => {
        if (projects) {
          return projectActions.allProjectsLoadedSuccessfully({ projects });
        }
        return projectActions.allProjectLoadCancelled();
      }),
    ),
  );

  notifyOnFailure = createEffect(
    () => {
      return this.actions.pipe(
        ofType(projectActions.updateProjectFailure, projectActions.projectCreationCancelled),
        map((action) => {
          this.notif.showError(action.errorMessage ?? 'Failed to update project');
        }),
      );
    },
    {
      dispatch: false,
    },
  );

  // it doesn't load if there are projects
  loadProjectsSimple$ = createEffect(() =>
    this.actions.pipe(
      ofType(projectActions.loadProjectsSimple),
      withLatestFrom(this.store.select(getSimpleProjects)),
      switchMap(([action, simpleProjects]) => {
        if (simpleProjects?.length > 0) {
          // it doesn't load if there are projects
          return of(null);
        }
        if (action.filters) {
          return this.projectsApi.getSimpleProjects(action.filters);
        }
        return this.getDefaultFilters().pipe(
          switchMap((filters: IProjectFilters) => {
            return this.projectsApi.getSimpleProjects(filters);
          }),
        );
      }),
      catchError((err) => {
        console.warn('loadProjectsSimple$ err on load', err);
        this.notif.showError('Failed to load projects data: ' + err?.error?.message);
        return of(null);
      }),
      map((projects) => {
        if (!projects) {
          return projectActions.simpleProjectLoadCancelled();
        }
        return projectActions.simpleProjectsLoadedSuccessfully({ projects });
      }),
    ),
  );

  loadBackendStatuses = createEffect(() =>
    this.actions.pipe(
      ofType(projectActions.loadBackendStatuses),
      switchMap((_) => {
        return this.projectsApi.getPossibleStatuses$();
      }),
      catchError((err) => {
        console.warn('err on load', err);
        return of(null);
      }),
      map((statuses: IProjectStatus[] | null) => {
        if (!statuses || !statuses?.length) {
          return projectActions.cancelStatusLoading();
        }
        statuses.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        return projectActions.statusesLoadedSuccessfully({ statuses });
      }),
    ),
  );

  createProject = createEffect(() =>
    this.actions.pipe(
      ofType(projectActions.createProject),
      switchMap((action) => {
        return this.projectsApi.createProject$(action.project);
      }),
      map((project: Project) => {
        return projectActions.projectCreatedSuccessfully({ projectId: project.id });
      }),
      catchError((err: any) => {
        console.warn('err on project create', err);
        return of(
          projectActions.projectCreationCancelled({
            errorMessage: err?.error?.message ?? 'Failed to create project',
          }),
        );
      }),
      repeat(),
    ),
  );

  editProjectInitiated = createEffect(() =>
    this.actions.pipe(
      ofType(projectActions.editProjectInitiated),
      switchMap((action) => {
        return this.projectsApi.getProjectById$(action.id);
      }),
      map((project: Project) => {
        if (!project?.id) {
          return projectActions.failedToInitiateProjectEdit();
        }
        return projectActions.editProjectDataLoaded({ project });
      }),
    ),
  );

  deleteProject = createEffect(() =>
    this.actions.pipe(
      ofType(projectActions.deleteProject),
      exhaustMap((action) => {
        return this.projectsApi.deleteProject$(action.id);
      }),
      catchError((err) => {
        this.notif.showError(err?.error?.message || 'Failed to delete project');
        console.warn('err on load', err);
        return of(null);
      }),
      map((data) => {
        if (!data) {
          this.notif.showError('Failed to delete project');
          return projectActions.failedToDeleteProject();
        }
        return projectActions.successfulProjectDeletion();
      }),
    ),
  );

  updateProject = createEffect(() =>
    this.actions.pipe(
      ofType(projectActions.updateProject),
      switchMap((action) => {
        return this.projectsApi.updateProject$(action.project);
      }),
      map((_) => {
        return projectActions.updateProjectSuccess();
      }),
      catchError((err) => {
        console.warn('err on project update', err);
        return of(
          projectActions.updateProjectFailure({
            errorMessage: err?.error?.message ?? 'Failed to update project',
          }),
        );
      }),
      repeat(),
    ),
  );

  getDefaultFilters(): Observable<IProjectFilters> {
    return this.store.select(getProjectStatuses).pipe(
      filter((projectStatuses) => !!projectStatuses && projectStatuses.length > 0),
      take(1),
      map((projectStatuses) => {
        const statuses = projectStatuses
          .filter(
            (status) =>
              status.key !== 'archived' && status.key !== 'deleted' && status.key !== 'cancelled',
          )
          .map((status) => status.key as ProjectStatusKey);
        return { status_filter: statuses };
      }),
    );
  }
}

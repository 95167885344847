@if (label) {
  <div class="uppercase text-size_xs font-semibold relative top-1 text-shade_2">{{ label }}</div>
}
<div
  (fileDropped)="onFileDropped($event)"
  [ngClass]="{ 'p-disabled': disableUpload }"
  appUploadDrop
  class="border border-shade_5 border-solid p-3 mt-1.5 upload-drop-area"
>
  <span (click)="onOpenWindow()" class="text-color_secondary flex items-center gap-2.5 pointer-cursor">
    <span class="icon-upload flex items-center"></span>
    <span class="italic text-color_secondary font-bold text-size_l">{{ placeholder }}</span>
  </span>
  <ng-container *ngIf="files?.length">
    <span
      (click)="onOpenWindow()"
      *ngFor="let file of files; index as index"
      class="flex items-center gap-5 pointer-cursor"
    >
      <span class="text-color_secondary my-1.5 flex-1">
        {{ file.name | shortenFileName }}
      </span>
      <span
        (click)="onRemoveFile(file, index)"
        [ngClass]="{ 'p-disabled': disableUpload, hidden: hideRemoveFileIcon }"
        class="icon-remove-circle-x text-color_secondary hover:text-color_reject"
      ></span>
    </span>
  </ng-container>
</div>

<div class="sidebar-wrapper">
  <app-sidebar-header (backButtonClicked)="goToPreviousPage()" [showBackButton]="currentPage > 1">
    <div smallTitle>Create RFI</div>
    <div mainTitle>Request For Information</div>
  </app-sidebar-header>
  <div class="main-content flex flex-col">
    <ng-scrollbar #scrollbar class="std-scrollbar flex-1" [appearance]="'compact'" orientation="vertical">
      <div [ngClass]="{ 'mr-3': scrollbar.isVerticallyScrollable() }" class="flex flex-col">
        @if (currentPage === CREATE_RFI_PAGE.FIRST_PAGE) {
          <app-floating-input label="RFI Number" placeholder="RFI Number"></app-floating-input>

          <app-floating-input label="RFI Tags" placeholder="RFI Tags"></app-floating-input>

          <div class="flex gap-4">
            <app-floating-input class="flex-1" label="Originator" placeholder="Originator"></app-floating-input>
            <app-floating-input
              class="flex-1"
              label="Originator RFI ID"
              placeholder="Originator RFI ID"
            ></app-floating-input>
          </div>

          <app-floating-input label="Subject" placeholder="Subject"></app-floating-input>

          <app-dropdown label="RFI Manager" placeholder="RFI Manager"></app-dropdown>
          <app-dropdown label="Responsibility" placeholder="Responsibility"></app-dropdown>
          <app-input-calendar placeholder="Due Date"></app-input-calendar>

          <div class="flex gap-4">
            <app-dropdown
              name="cost_impact"
              [(model)]="model.cost_impact"
              class="flex-1"
              label="Cost Impact"
              placeholder="Cost Impact"
              [options]="costImpactOptions"
            ></app-dropdown>
            <app-floating-input
              name="estimated_amount"
              class="flex-1"
              label="Estimated Amount"
              placeholder="Estimated Amount"
              [disabled]="model.cost_impact === 'no'"
            ></app-floating-input>
          </div>

          <div class="flex gap-4">
            <app-dropdown
              name="schedule_impact"
              [(model)]="model.schedule_impact"
              class="flex-1"
              label="Schedule Impact"
              placeholder="Schedule Impact"
              [options]="scheduleImpactOptions"
            ></app-dropdown>
            <app-floating-input
              name="estimated_time"
              class="flex-1"
              label="Estimated Time"
              placeholder="Estimated Time"
              [disabled]="model.schedule_impact === 'no'"
            ></app-floating-input>
          </div>
        } @else if (currentPage === CREATE_RFI_PAGE.SECOND_PAGE) {
          <app-dropdown
            class="participants-dropdown"
            wrapperClass="!mb-0"
            label="Participants"
            placeholder="Participants"
          ></app-dropdown>
          <div class="participants-list">
            <ng-scrollbar class="std-scrollbar" orientation="vertical">
              <div class="participant">Just a Participant</div>
              <div class="participant">Another Participant</div>
              <div class="participant">Just a Participant</div>
              <div class="participant">Another Participant</div>
              <div class="participant">Just a Participant</div>
              <div class="participant">Another Participant</div>
            </ng-scrollbar>
          </div>

          <div class="mt-2">
            <app-input-upload
              [allowMultipleFiles]="true"
              label="Attcachments"
              placeholder="Click to upload files or drag & drop"
            >
            </app-input-upload>
          </div>

          <div class="flex gap-4">
            <app-floating-input
              [showOptional]="true"
              class="flex-1"
              label="Drawing ID"
              placeholder="Drawing ID"
            ></app-floating-input>
            <app-floating-input
              [showOptional]="true"
              class="flex-1"
              label="Section Reference"
              placeholder="Section Reference"
            ></app-floating-input>
          </div>

          <div class="flex-1 flex">
            <textarea class="flex-1 border border-shade_5 rounded-s min-h-60">
          TODO: Add a rich text editor here
        </textarea
            >
          </div>
        }
      </div>
    </ng-scrollbar>
  </div>
  <div class="buttons flex justify-end gap-4">
    <app-simple-button (click)="saveDraft()" buttonStyle="color_secondary_border">Save Draft</app-simple-button>

    @if (currentPage === 1) {
      <app-simple-button (click)="goToNextPage()">Next</app-simple-button>
    } @else {
      <app-simple-button (click)="submitForm()" buttonStyle="color_accent_filled">Submit</app-simple-button>
    }
  </div>
</div>

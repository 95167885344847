import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { Project } from '../../../../pages/webapp/projects/projects.interface';
import { getStoreUpdates } from '../../../../store/spend/spend.selectors';
import { delay, takeUntil, tap } from 'rxjs/operators';
import { ILineItem } from '../../../../store/spend/spend.interfaces';
import { TooltipModule } from 'primeng/tooltip';
import dayjs from 'dayjs';
import { propertiesActions } from '../../../../store/properties/properties.actions';
import { propertiesSelectors } from '../../../../store/properties/properties.selector';
import { projectActions } from '../../../../store/projects/projects.actions';
import { ofType } from '@ngrx/effects';
import { loadSpends } from '../../../../store/spend/spend.actions';
import { IProperty } from '../../../../store/properties/properties.interfaces';
import { AsyncPipe, NgClass } from '@angular/common';
import { PageLoadingComponent } from '../../../page-loading/page-loading.component';
import { NgScrollbar } from 'ngx-scrollbar';
import { FloatingInputComponent } from '../../../inputs/floating-input/floating-input.component';
import { InputCalendarComponent } from '../../../inputs/input-calendar/input-calendar.component';
import { DropdownComponent } from '../../../inputs/dropdown/dropdown.component';
import { TagInputComponent } from '../../../inputs/tag-input/tag-input.component';
import { ProjectSetupBaseComponent } from '../project-setup-base.component';

@Component({
  selector: 'app-project-setup-manager',
  standalone: true,
  imports: [
    FormsModule,
    TooltipModule,
    AsyncPipe,
    PageLoadingComponent,
    NgScrollbar,
    NgClass,
    FloatingInputComponent,
    InputCalendarComponent,
    DropdownComponent,
    TagInputComponent,
  ],
  templateUrl: './project-setup-manager.component.html',
  styleUrl: './project-setup-manager.component.scss',
})
export class ProjectSetupManagerComponent extends ProjectSetupBaseComponent implements OnInit {
  @Input() isEdit: boolean;
  @ViewChild('setupForm') setupForm: NgForm;

  properties: IProperty[] = [];
  earliestLineItemDate: string | null;
  oldSelectedProperty: number = null;

  properties$ = this.store.select(propertiesSelectors.getAllProperties);
  lastStoreUpdate$ = this.store.select(getStoreUpdates);

  model: Partial<Project> = {
    status: null,
    title: '',
    start_date: null,
    property_id: null,
    tags: [],
    id: null,
    budget_template_id: null,
    budget_template: null,
    custom_project_id: '',
    auto_generated_project_id: null,
  };

  ngOnInit() {
    super.ngOnInit();
    this.loadData();

    this.properties$.pipe(takeUntil(this.isDestroyed$)).subscribe((properties) => {
      this.properties = properties;
    });

    this.actions
      .pipe(
        takeUntil(this.isDestroyed$),
        ofType(projectActions.editProjectDataLoaded),
        tap((action) => {
          this.store.dispatch(loadSpends({ projectId: action.project.id }));
        }),
        delay(100),
      )
      .subscribe((action) => {
        const project = action.project as Project;
        this.model = {
          id: project.id,
          status: project.status,
          title: project.title,
          start_date: project.start_date,
          property_id: project.property_id,
          tags: [...project.tags],
          budget_template_id: project.budget_template_id,
          budget_template: project.budget_template,
          custom_project_id: project.custom_project_id ?? project.auto_generated_project_id,
        };
        // can edit template if no commitments
        this.hasCommitments = project.has_commitments_or_mods;
        this.originalCustomId = project.auto_generated_project_id;
        this.hasCustomId = project.custom_project_id !== project.auto_generated_project_id;
        if (this.hasCustomId && !this.hasCommitments) {
          this.customIdEditable = true;
        }

        this.changeProperty(this.model.property_id);
        this.determineEarliestLineItemDate();
      });
  }

  loadData() {
    this.store.dispatch(propertiesActions.loadProperties({}));
  }

  determineEarliestLineItemDate() {
    this.lastStoreUpdate$.pipe(takeUntil(this.isDestroyed$)).subscribe((storeUpdate) => {
      const { lineItems } = storeUpdate;

      if (!lineItems || lineItems.length === 0) {
        this.earliestLineItemDate = null;
        return;
      }

      const lineItemDates = lineItems.map((item) => this.lineItemMinDate(item));
      this.earliestLineItemDate = dayjs.min(lineItemDates).format('YYYY-MM-DD');
    });
  }

  lineItemMinDate(item: ILineItem) {
    return dayjs(item.start_date);
  }

  /**
   * If properties changed, set default project template and if needed ask the
   * user if he/she wants to reset the budget.
   */
  propertiesChanged(newPropertyId: number) {
    const selectedProperty = this.properties.find((property) => property.id === newPropertyId);

    if (this.hasCommitments && selectedProperty?.team_id !== this.model?.budget_template?.team_id) {
      this.notif.showError('Property change blocked due to commitments and team mismatch.');
      this.changeProperty(this.oldSelectedProperty);
      return;
    }

    if (
      selectedProperty?.team_id === this.model?.budget_template?.team_id ||
      !this.model?.budget_template
    ) {
      this.changeProperty(newPropertyId);
    } else {
      this.notif
        .showPopup(
          'Selected property does not have the same team as the selected budget template. Template will reset. Continue?',
        )
        .then((resp) => {
          if (resp) {
            this.changeProperty(newPropertyId);
            this.model.budget_template_id = null;
          } else {
            this.changeProperty(this.oldSelectedProperty);
          }
        });
    }
  }

  /**
   * Changes the property and filters and resets the budget template.
   */
  changeProperty(propertyId: number) {
    this.oldSelectedProperty = propertyId;
    this.model.property_id = propertyId;

    if (!this.isEdit) {
      this.updateProjectId({
        propertyId,
        year: this.model.start_date ? dayjs(this.model.start_date).year() : undefined,
      });
    }
  }

  updateProjectId({ propertyId, year }: { propertyId?: number; year?: number }) {
    if (!propertyId || !year) {
      return;
    }

    this.projectService
      .getNextProjectId({ property_id: propertyId, year })
      .subscribe((response) => {
        this.model.custom_project_id = response.next_custom_id;
        this.originalCustomId = response.next_custom_id;
      });
  }

  startDateChanged(date: string) {
    if (!this.isEdit) {
      this.updateProjectId({ propertyId: this.model.property_id, year: dayjs(date).year() });
    }
  }
}

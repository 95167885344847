import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CurrentUserService } from '../../services/current-user.service';
import { UntypedFormControl } from '@angular/forms';
import { ProjectApiService } from '../../services/project-api.service';
import { NotificationsService } from '../../services/notifications.service';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatFormField } from '@angular/material/form-field';
import { NgClass, NgFor, NgIf, TitleCasePipe } from '@angular/common';
import { PROJECT_STATUS_KEY } from '../../pages/webapp/projects/projects.interface';

@Component({
  selector: 'app-project-bubble',
  templateUrl: './project-bubble.component.html',
  styleUrls: ['./project-bubble.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, MatFormField, MatSelect, NgFor, MatOption, TitleCasePipe],
})
export class ProjectBubbleComponent implements OnInit {
  _project;
  _allStatuses = [];
  statusControl = new UntypedFormControl('');
  availableStatuses = []; // available statuses can be different than all statuses if needed
  PROJECT_STATUS_KEY = PROJECT_STATUS_KEY;
  selectedStatus: { id: number; name: string };

  @Input() isDropDown = false;
  @Input() set allStatuses(value) {
    if (value) {
      this._allStatuses = [...value];
      // available statuses can be different than all statuses if needed
      this.availableStatuses = [...this.allStatuses];
    }
  }
  get allStatuses() {
    return this._allStatuses;
  }

  @Input() set project(project) {
    if (project) {
      project = { ...project };
      this.selectedStatus = project.project_status;
      this.statusControl.setValue(this.selectedStatus?.id);
      this._project = project;
    }
  }
  get project() {
    return this._project;
  }

  @Input() center = true;

  @Output() resetData = new EventEmitter<{ projectId: number; newStatus: number | string }>();

  constructor(
    public user: CurrentUserService,
    public projectApi: ProjectApiService,
    public notif: NotificationsService,
  ) {}

  ngOnInit(): void {}

  changeProjectStatus(newStatus) {
    if (newStatus === PROJECT_STATUS_KEY.DELETED && this.user.isManagerLike) {
      this.notif
        .showPopup('This project will be permanently deleted. Do you want to proceed?')
        .then((response) => {
          if (response) {
            this.finishChangeProjectStatus(newStatus);
          } else {
            this.selectedStatus = this.project.project_status;
            this.resetData.emit();
          }
        });
    } else {
      this.finishChangeProjectStatus(newStatus);
    }
  }

  finishChangeProjectStatus(newStatus) {
    this.notif.showLoading(); // it is closed from the projects-table.component
    this.selectedStatus = { id: newStatus, name: '' };
    this.projectApi.updateProjectStatus(this.project.id, newStatus).then(
      (data) => {
        this.resetData.emit();
        this.notif.close();
      },
      (err) => {
        this.resetData.emit();
        this.notif.showError(err);
      },
    );
  }
}

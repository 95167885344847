import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  contractorTypes,
  MANAGE_PROJECT_TABS,
  PROJECT_VIEWS,
} from '../../../../../framework/constants/view-project.constants';
import { CommonModule } from '@angular/common';
import { ProjectApiService } from '../../../../../services/project-api.service';
import { Store } from '@ngrx/store';
import { viewProjectSelectors } from '../../../../../store/view-project/view-project.selectors';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { combineLatest, Subject } from 'rxjs';
import {
  CONTRACTOR_TYPE,
  InvitedContractor,
  USER_MANAGER,
} from '../../../../../framework/constants/user.constants';

import { BID_STATUS } from 'src/app/framework/constants/project.constants';
import { ContractorsApiService } from '../../../../../services/contractors-api.service';
import { NotificationsService } from '../../../../../services/notifications.service';
import { UnStyledOptionsListComponent } from '../../../../../framework/overlays/un-styled-options-list/un-styled-options-list.component';
import { OPTIONS } from '../../../../../framework/constants/options-list.constants';
import { Router } from '@angular/router';
import { DriveService } from '../../../../../services/drive.service';
import { MessagesStateService } from '../../../../../services/messages-state.service';
import { PageLoadingComponent } from '../../../../../framework/page-loading/page-loading.component';
import {
  COMMITMENTS_TYPE,
  INTERACTION_BAR_STATES,
} from '../../../../../framework/constants/interaction-bar.constants';
import { InteractionBarStateService } from '../../../../../services/interaction-bar-state.service';
import { SpendViewProjectStateService } from '../../../../../services/spend-view-project-state.service';
import { ILineItem } from '../../../../../store/spend/spend.interfaces';
import { viewProjectActions } from '../../../../../store/view-project/view-project.actions';
import { SPEND_TYPES } from '../../../../../framework/constants/budget.constants';
import { setSelectedSpendType } from '../../../../../store/spend/spend.actions';
import { CurrentUserService } from '../../../../../services/current-user.service';
import { BidProposalComponent } from '../bid-proposal/bid-proposal.component';
import { MoneyPipe } from '../../../../../pipes/framework/money-short.pipe';
import { commitmentsSelectors } from '../../../../../store/commitments/commitments.selectors';
import { TooltipModule } from 'primeng/tooltip';
import { NgScrollbar } from 'ngx-scrollbar';

@Component({
  selector: 'app-manage-project',
  standalone: true,
  imports: [
    CommonModule,
    UnStyledOptionsListComponent,
    PageLoadingComponent,
    BidProposalComponent,
    MoneyPipe,
    TooltipModule,
    NgScrollbar,
  ],
  templateUrl: './manage-project.component.html',
  styleUrl: './manage-project.component.scss',
})
export class ManageProjectComponent implements OnInit, OnDestroy {
  tabs = MANAGE_PROJECT_TABS;
  selectedTab: MANAGE_PROJECT_TABS;
  isLoading = true;
  projectId$ = this.store.select(viewProjectSelectors.getProjectId);
  projectId: number;
  contractorTypes = contractorTypes;
  lineItems$ = this.store.select(viewProjectSelectors.getLineItems);
  lineItems: ILineItem[];
  hasContract$ = this.store.select(commitmentsSelectors.hasSomeContract);

  protected readonly SPEND_TYPES = SPEND_TYPES;
  protected readonly USER_MANAGER = USER_MANAGER;

  contractors: { [key in CONTRACTOR_TYPE]?: InvitedContractor[] } = {
    [CONTRACTOR_TYPE.CONTRACTOR]: [],
    [CONTRACTOR_TYPE.ARCHITECT]: [],
    [CONTRACTOR_TYPE.SUBCONTRACTOR]: [],
    [CONTRACTOR_TYPE.INSPECTOR]: [],
    [CONTRACTOR_TYPE.ENGINEER]: [],
    [CONTRACTOR_TYPE.MATERIAL_SUPPLIER]: [],
    [CONTRACTOR_TYPE.CONSULTANT]: [],
  };
  // key: contractorId
  isFavoriteLoading: { [key: number]: boolean } = {};
  itemOptions: { [key: number]: OPTIONS[] } = {};
  disabledOptions: { [key: number]: OPTIONS[] } = {};

  BID_STATUS = BID_STATUS;

  COMMITMENTS_TYPE = COMMITMENTS_TYPE;
  INTERACTION_BAR_STATES = INTERACTION_BAR_STATES;

  isDestroyed$ = new Subject<boolean>();
  constructor(
    private projectApi: ProjectApiService,
    private store: Store,
    private contractorService: ContractorsApiService,
    private notif: NotificationsService,
    private router: Router,
    private driveService: DriveService,
    private messagesStateService: MessagesStateService,
    private spendViewStateService: SpendViewProjectStateService,
    private interactionBarService: InteractionBarStateService,
    public userService: CurrentUserService,
  ) {}

  ngOnInit() {
    this.loadContractors();
    this.lineItems$.pipe(takeUntil(this.isDestroyed$)).subscribe((lineItems) => {
      this.lineItems = lineItems;
    });

    combineLatest([this.interactionBarService.announceState, this.userService.data$])
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(([state, user]) => {
        if (state?.action !== INTERACTION_BAR_STATES.MANAGE_PROJECT) {
          return;
        }
        if (state?.data?.tab && state?.data?.tab in MANAGE_PROJECT_TABS) {
          this.selectedTab = state.data.tab;
          return;
        }

        this.selectedTab = user?.isManagerLike
          ? MANAGE_PROJECT_TABS.SERVICE_TEAM
          : MANAGE_PROJECT_TABS.COMMITMENTS;
      });
  }

  setSelectedTab(tab: MANAGE_PROJECT_TABS) {
    this.selectedTab = tab;
  }
  loadContractors() {
    this.isLoading = true;

    this.projectId$
      .pipe(
        takeUntil(this.isDestroyed$),
        tap((id) => {
          this.projectId = id;
        }),
        switchMap((projectId) => this.projectApi.getInvitedContractors(projectId)),
      )
      .subscribe({
        next: (backendData) => {
          this.isLoading = false;
          const invitedContractors = backendData?.[0]?.invited_contractors;

          if (!invitedContractors) {
            return;
          }
          this.clearContractorsList();

          for (const contractor of invitedContractors) {
            // only type if backend won't change back
            if (!contractor?.user?.type?.id) {
              continue;
            }
            this.contractors[contractor.user.type.id].push(contractor);
            this.setContractorOptions(contractor);
          }
        },
        error: (err) => {
          this.notif.showError('Error loading contractors');
          console.log(err);
          this.isLoading = false;
        },
      });
  }

  private clearContractorsList() {
    for (const key of Object.keys(this.contractors)) {
      this.contractors[key] = [];
    }
  }

  private setContractorOptions(contractor: InvitedContractor) {
    const options = [OPTIONS.VIEW_CONTRACT, OPTIONS.DOWNLOAD_BID, OPTIONS.MESSAGE, OPTIONS.DISMISS];
    const disabledOptions = [];

    if (!contractor.has_commitments) {
      disabledOptions.push(OPTIONS.VIEW_CONTRACT);
    }
    if (!contractor.bid) {
      disabledOptions.push(OPTIONS.DOWNLOAD_BID);
    }

    this.itemOptions[contractor.id] = options;
    this.disabledOptions[contractor.id] = disabledOptions;
  }

  isContractorsListEmpty() {
    for (const contractorList of Object.values(this.contractors)) {
      if (contractorList.length !== 0) {
        return false;
      }
    }
    return true;
  }

  toggleFavorite(contractor: InvitedContractor) {
    this.isFavoriteLoading[contractor.id] = true;
    this.contractorService
      .setFavoriteWithObservable(contractor.id, !contractor.is_favorite)
      .subscribe({
        next: (_) => {
          this.isFavoriteLoading[contractor.id] = false;
          this.contractors[contractor.user.type.id] = this.contractors[contractor.user.type.id].map(
            (c) => {
              if (c.id === contractor.id) {
                return { ...c, is_favorite: !c.is_favorite };
              }
              return c;
            },
          );
        },
        error: (err) => {
          this.isFavoriteLoading[contractor.id] = false;
          this.notif.showError('Error updating favorite status');
          console.log(err);
        },
      });
  }
  async registerItemOption(option: OPTIONS, contractor: InvitedContractor) {
    switch (option) {
      case OPTIONS.VIEW_CONTRACT: {
        // todo: navigate to the specific contract
        this.router.navigate(['webapp', 'projects', this.projectId], {
          queryParams: { view: PROJECT_VIEWS.COMMITMENTS },
        });
        break;
      }
      case OPTIONS.DOWNLOAD_BID: {
        this.driveService.downloadSelectedDocuments(
          [contractor.bid.user_drive_file],
          contractor?.bid?.user_drive_file?.name ?? '',
        );
        break;
      }
      case OPTIONS.MESSAGE: {
        this.messagesStateService.newMessageTo(contractor.user);
        break;
      }
      case OPTIONS.DISMISS: {
        const resp = await this.notif.showPopup('Dismiss service provider?');
        if (!resp) {
          return;
        }
        if (!contractor.invite) {
          this.notif.showError('Invite not found!');
          return;
        }

        this.projectApi.deleteInvite(contractor.invite.id).subscribe({
          next: (data) => {
            this.notif.showSuccess('Service provider dismissed!');
            this.loadContractors();
          },
          error: (err) => {
            this.notif.showError(err);
          },
        });

        break;
      }
    }
  }

  addCommitment(type: INTERACTION_BAR_STATES) {
    this.interactionBarService.openCommitments(type);
  }

  openAnticipatedCost() {
    this.interactionBarService.openAnticipatedCosts();
  }

  /**
   * Open the spend view after closing the interaction bar with a delay (looks better)
   */
  setSpendView(budgetType: SPEND_TYPES) {
    this.interactionBarService.close();
    this.notif.showLoading();
    setTimeout(() => {
      // setTimeout is needed to avoid freezing the UI
      this.store.dispatch(
        viewProjectActions.viewChanged({ view: PROJECT_VIEWS.BUDGET_ALLOCATION }),
      );

      // not sure why this dispatch is needed, the changeBudgetType() should do it...
      // this is needed otherwise the view won't change
      this.store.dispatch(setSelectedSpendType({ spendType: budgetType }));
    }, 500);
  }

  openBudgetAdjustment() {
    this.interactionBarService.openBudgetAdjustment({
      projectId: this.projectId,
      lineItems: this.lineItems,
    });
  }

  /**
   * Open the history page after closing the interaction bar with a delay(looks better)
   */
  setHistoryView() {
    this.interactionBarService.close();
    setTimeout(() => {
      this.store.dispatch(viewProjectActions.viewChanged({ view: PROJECT_VIEWS.BUDGET_HISTORY }));
    }, 500);
  }

  addProposal() {
    this.selectedTab = MANAGE_PROJECT_TABS.PROPOSAL;
  }

  ngOnDestroy() {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }
}

<div class="flex flex-1 h-full flex-col">
  <app-general-sidebar-header
    [title]="(model.id ? 'Edit' : 'Add') + ' Item to Track'"
    subtitle="Item Information"
  ></app-general-sidebar-header>

  <ng-scrollbar class="std-scrollbar flex-1 min-h-0" appearance="native" orientation="vertical">
    <div class="my-5 horizontal-spacing">
      <form class="h-full" name="ngForm" #form="ngForm" role="form">
        <span class="p-float-label flex flex-1 flex-col px-7">
          <app-floating-input
            required
            [(model)]="model.title"
            name="title"
            label="Item Title"
            placeholder="Item Title"
          ></app-floating-input>

          <app-input-calendar
            required
            name="start_date"
            [showIcon]="true"
            [(date)]="model.start_date"
            [minStartDate]="project_start_date"
            [maxDate]="model.end_date"
            placeholder="Start Date"
          ></app-input-calendar>

          <app-input-calendar
            required
            name="end_date"
            placeholder="Due Date"
            [(date)]="model.end_date"
            [minStartDate]="model.start_date || project_start_date"
            [showIcon]="true"
          ></app-input-calendar>

          <app-checklist-input
            [minStartDate]="model.start_date || project_start_date"
            [maxDate]="model.end_date"
            [defaultDate]="model.end_date"
            [required]="true"
            [(model)]="model.checklist"
            [isEdited]="!!model.id"
          ></app-checklist-input>
        </span>
      </form>
    </div>
  </ng-scrollbar>

  <div class="flex items-center justify-end mb-7 mr-5 mt-2.5">
    <div
      (click)="saveTrackItem()"
      class="bg-color_secondary rounded text-white py-1 px-8 font-semibold text-sm cursor-pointer"
    >
      Submit
    </div>
  </div>
</div>

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { projectDocumentActionTypes } from './projectDocuments.actions';
import { DocumentItem, documentsAdapter } from '../documents/documents.reducer';
import { DeepCopyService } from '../../services/deep-copy.service';
import { sortFuncDrive } from '../../framework/constants/documents.constants';

export interface ProjectDocumentsState extends EntityState<DocumentItem> {
  isLoaded: boolean;
  projectId: number;
  retrieveDisabled: boolean; // will call API with /retrieveDisabled=1 on true
  selectedFolderId: number;
  selections: number[];
}

export const projectDocumentAdapter: EntityAdapter<DocumentItem> =
  createEntityAdapter<DocumentItem>();

export const initialState = projectDocumentAdapter.getInitialState({
  isLoaded: false,
  projectId: null,
  retrieveDisabled: false,
  selectedFolderId: null,
  selections: [],
});

export const projectDocumentsReducer = createReducer(
  initialState,

  on(projectDocumentActionTypes.updateAll, (state, action) => {
    const newState = {
      ...state,
      isLoaded: true,
      projectId: action.projectId ? action.projectId : state.projectId,
      selectedFolderId: action.data.id, // todo: check if this is correct
      selections: [action.data.id],
    };

    if (!action.data) {
      return documentsAdapter.setAll([], newState);
    }

    const actionData = DeepCopyService.deepCopy(action.data);
    actionData.setFolderPath = action.setFolderPath || false;
    actionData?.files?.sort(sortFuncDrive);
    actionData?.children?.sort(sortFuncDrive);
    return projectDocumentAdapter.setAll([actionData], newState);
  }),
  on(projectDocumentActionTypes.reloadProjDocs, (state, action) => {
    return {
      ...state,
      isLoaded: false,
    };
  }),

  on(projectDocumentActionTypes.navigateBack, (state, action) => {
    const selections = DeepCopyService.deepCopy(state.selections);
    if (selections.length <= 1) {
      return state;
    }
    selections.pop();
    return {
      ...state,
      isLoaded: true,
      selectedFolderId: selections[selections.length - 1],
      selections,
    };
  }),
  on(projectDocumentActionTypes.loadProjectFolder, (state, action) => {
    const newState = {
      ...DeepCopyService.deepCopy(state),
      selectedFolderId: action.folder.id,
      selections: [...state.selections, action.folder.id],
      isLoaded: true,
    };

    const actionCopy = DeepCopyService.deepCopy(action);
    actionCopy.folder?.children?.sort(sortFuncDrive);
    actionCopy.folder?.files?.sort(sortFuncDrive);
    return documentsAdapter.setOne(actionCopy.folder, newState);
  }),
  on(projectDocumentActionTypes.projectFolderClicked, (state, action) => {
    return {
      ...state,
      isLoaded: false,
    };
  }),
  on(projectDocumentActionTypes.updateProjectDriveFolder, (state, action) => {
    const newState = {
      ...state,
      isLoaded: true,
    };
    const actionCopy = DeepCopyService.deepCopy(action);
    const updatedEntities = [
      {
        id: actionCopy.folder.id,
        changes: {
          ...actionCopy.folder,
          children: actionCopy.folder.children.sort(sortFuncDrive),
          files: actionCopy.folder.files.sort(sortFuncDrive),
        },
      },
    ];
    // find and update parent folder of the currently accessed folder
    const parentId = state.selections[state.selections.length - 2];
    if (parentId) {
      const updatedChildren = state.entities[parentId].children.map((child) =>
        child.id === actionCopy.folder.id ? actionCopy.folder : child,
      );
      updatedChildren.sort(sortFuncDrive);
      updatedEntities.push({
        id: parentId,
        changes: {
          children: updatedChildren,
        },
      });
    }
    return documentsAdapter.updateMany(updatedEntities, newState);
  }),
  on(projectDocumentActionTypes.reset, (state, action) => {
    return {
      ...state,
      isLoaded: false,
    };
  }),

  on(projectDocumentActionTypes.clearCacheForNewDrive, (state, action) => {
    return projectDocumentAdapter.setAll([], {
      ...state,
      isLoaded: false,
      projectId: null,
      retrieveDisabled: true, // for add project needs to be true
      selections: [],
      selectedFolderId: null,
    });
  }),
  on(projectDocumentActionTypes.clearCache, (state, action) => {
    return projectDocumentAdapter.setAll([], {
      ...state,
      isLoaded: false,
      projectId: null,
      retrieveDisabled: false,
      selections: [],
      selectedFolderId: null,
    });
  }),
);

export const { selectAll, selectIds } = projectDocumentAdapter.getSelectors();

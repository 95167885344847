<div class="interaction-bar-wrapper reporting-bar">
  <div class="interaction-bar-title default-cursor">
    <app-back-button-box (clicked)="decrementPage()" *ngIf="currentPage > 0" class="text-initial"></app-back-button-box>
    <span [ngClass]="{ 'shift-left': currentPage > 0 }">Print Invoice Log:</span>
    <span class="font-normal ml-1" *ngIf="currentPage === PROPERTY_PAGE">Select Properties</span>
    <span class="font-normal ml-1" *ngIf="currentPage === PROJECTS_PAGE">Select Projects</span>
    <span class="font-normal ml-1" *ngIf="currentPage === SP_PAGE">Select Service Providers</span>
    <span class="font-normal ml-1" *ngIf="currentPage === DATES_PAGE">Select Date Ranges</span>
  </div>

  <app-loading-pulse class="contents" *ngIf="isLoading"></app-loading-pulse>

  <div *ngIf="!isLoading" class="interaction-bar-content" [formGroup]="reportFields">
    <div *ngIf="currentPage === PROPERTY_PAGE" class="page">
      <app-floating-input
        placeholder="Search"
        label="Search"
        name="property_search"
        [(model)]="propertySearch"
        (iconClick)="onPropertyIconClick()"
        (modelChange)="propertySearchChanged($event)"
        [iconClass]="{
          position: 'p-input-icon-right',
          icon: propertySearch
            ? 'icon-add rotate-45 text-shade_3 cursor-pointer hover:text-color_reject'
            : 'icon-search text-shade_3'
        }"
      ></app-floating-input>
      <ng-scrollbar appearance="native" orientation="vertical" class="std-scrollbar reporting-scroll">
        <div class="text-shade_2 text-center px-2 py-5" *ngIf="propertiesToList.length === 0">
          There are no properties corresponding to the search.
        </div>
        <div
          *ngIf="!propertySearch && propertiesToList.length > 0"
          (click)="toggleAllValues('property_ids', allPropertyIds)"
          class="checklist-item"
        >
          <app-checklist-item title="All Properties" [checked]="isAllSelected['property_ids']"></app-checklist-item>
        </div>
        <div (click)="toggleProperty(property.id)" *ngFor="let property of propertiesToList" class="checklist-item">
          <app-checklist-item
            [title]="property.name"
            [subtitle]="property.formatted_address"
            [checked]="reportFields.get('property_ids').value.includes(property.id)"
          ></app-checklist-item>
        </div>
      </ng-scrollbar>
    </div>

    <div *ngIf="currentPage === PROJECTS_PAGE" class="page">
      <app-floating-input
        placeholder="Search"
        label="Search"
        name="project_search"
        [(model)]="projectSearch"
        (iconClick)="onProjectIconClick()"
        [iconClass]="{
          position: 'p-input-icon-right',
          icon: projectSearch
            ? 'icon-add rotate-45 text-shade_3 cursor-pointer hover:text-color_reject'
            : 'icon-search text-shade_3'
        }"
      ></app-floating-input>
      <ng-scrollbar appearance="native" orientation="vertical" class="std-scrollbar reporting-scroll">
        <div class="text-shade_2 text-center px-2 py-5" *ngIf="filteredProjects.length === 0">
          There are no projects corresponding to the selected years, properties or search.
        </div>
        <div
          *ngIf="!projectSearch && filteredProjects.length > 0"
          (click)="toggleAllValues('project_ids', filteredProjectIdsWithoutSearchTerm)"
          class="checklist-item"
        >
          <app-checklist-item title="All Projects" [checked]="isAllSelected['project_ids']"></app-checklist-item>
        </div>
        <div (click)="toggleProject(project.id)" *ngFor="let project of filteredProjects" class="checklist-item">
          <app-checklist-item
            [title]="project.title"
            [subtitle]="project?.project_property?.formatted_address ?? ''"
            [checked]="reportFields.get('project_ids').value.includes(project.id)"
          ></app-checklist-item>
        </div>
      </ng-scrollbar>
    </div>

    <div *ngIf="currentPage === SP_PAGE" class="page">
      <app-floating-input
        placeholder="Search for a company..."
        label="Search"
        name="companies_search"
        [(model)]="serviceProviderSearch"
        (iconClick)="onCompanyIconClick()"
        [iconClass]="{
          position: 'p-input-icon-right',
          icon: serviceProviderSearch
            ? 'icon-add rotate-45 text-shade_3 cursor-pointer hover:text-color_reject'
            : 'icon-search text-shade_3'
        }"
      ></app-floating-input>
      <ng-scrollbar appearance="native" orientation="vertical" class="std-scrollbar reporting-scroll">
        <div class="text-shade_2 text-center px-2 py-5" *ngIf="filteredServiceProvidersList.length === 0">
          There are no companies corresponding to your search.
        </div>
        <div
          *ngIf="!serviceProviderSearch && serviceProvidersList.length > 0"
          (click)="toggleAllSP()"
          class="checklist-item"
        >
          <app-checklist-item
            title="All Companies"
            [checked]="isAllSelected['service_provider_ids']"
          ></app-checklist-item>
        </div>
        <div
          (click)="toggleSP(serviceProvider.id)"
          *ngFor="let serviceProvider of filteredServiceProvidersList"
          class="checklist-item"
        >
          <app-checklist-item
            [title]="serviceProvider.name"
            [checked]="this.reportFields.get('service_provider_ids').value.includes(serviceProvider.id)"
          ></app-checklist-item>
          <!--            [subtitle]="company?.project_property?.formatted_address ?? ''"-->
        </div>
      </ng-scrollbar>
    </div>

    <div *ngIf="currentPage === DATES_PAGE" class="page">
      <form #dateRangesPageForm="ngForm" role="form" class="flex gap-4 items-center">
        <app-input-calendar
          name="invoice_log_start_date"
          class="flex-1"
          [(date)]="dateRanges.invoice_log_start_date"
          [maxDate]="dateRanges.invoice_log_end_date"
          (dateChange)="checkDatesValidation()"
          [showIcon]="true"
          [required]="true"
          [isDisabled]="false"
          placeholder="Start Date"
        ></app-input-calendar>

        <app-input-calendar
          name="invoice_log_end_date"
          class="flex-1"
          [(date)]="dateRanges.invoice_log_end_date"
          [minStartDate]="dateRanges.invoice_log_start_date"
          (dateChange)="checkDatesValidation()"
          [showIcon]="true"
          [required]="true"
          [isDisabled]="false"
          placeholder="End Date"
        ></app-input-calendar>
      </form>
    </div>

    <div class="text-color_reject text-center my-2">
      {{ errorMessage }}
    </div>
    <button
      *ngIf="currentPage < MAX_PAGE; else saveButton"
      (click)="incrementPage()"
      [disabled]="nextDisabled"
      [ngClass]="{ disabled: nextDisabled }"
      class="btn next-btn"
      mat-button
    >
      <span *ngIf="!isLoading">Next</span>
      <span *ngIf="isLoading" class="icon-refresh animate-spin"></span>
    </button>
    <ng-template #saveButton>
      <button
        (click)="fillFormAndSubmit()"
        [ngClass]="{ disabled: submitDisabled }"
        [disabled]="submitDisabled"
        class="btn"
        mat-button
      >
        <span>Generate report</span>
      </button>
    </ng-template>
  </div>
</div>

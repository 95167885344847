<div class="flex flex-1 h-full flex-col">
  <div class="font-semibold text-sm text-shade_2 pt-6 px-8 pb-10 w-full flex justify-between items-center box-border">
    {{ (model.id ? 'Edit' : 'Add') + ' Daily Activity' }}

    <div
      (click)="toggleCompletion()"
      *ngIf="model.id"
      class="mark-complete"
      [ngClass]="{ completed: model.completed, 'disable-element': disableCompletion }"
    >
      {{ model.completed ? 'Completed' : 'Mark Complete' }}
    </div>
  </div>
  <div class="border-b border-solid border-shade_5 -mt-2"></div>

  <ng-scrollbar class="std-scrollbar" appearance="native" orientation="vertical">
    <div class="mb-5 mt-2 pt-1 h-full horizontal-spacing scrollable-content">
      <form class="h-full" name="ngForm" #form="ngForm" role="form">
        <app-floating-input
          required
          [(model)]="model.title"
          name="title"
          label="Title or short description"
          placeholder="Title or short description"
        ></app-floating-input>

        <!--          leave optionValue empty to use the whole object as the value -->
        <app-dropdown
          optionLabel="title"
          optionValue="id"
          name="project"
          label="Projects"
          placeholder="Projects"
          [options]="possibleProjects"
          [(model)]="model.project_id"
          (modelChange)="onProjectChange($event)"
          required
        >
          <ng-template #selectedContent let-option>
            <div
              class="dropdown-item"
              [ngClass]="{ 'text-shade_2 font-semibold text-co': option.id === noRelatedProject.id }"
            >
              {{ option.title | titlecase }}
            </div>
          </ng-template>
          <ng-template #optionContent let-option>
            <div class="dropdown-item" [ngClass]="{ 'text-shade_2 font-semibold': option.id === noRelatedProject.id }">
              {{ option.title | titlecase }}
            </div>
            <div class="pt-2 pb-0.5" *ngIf="areSimpleProjectsLoading$ | async">Loading ...</div>
          </ng-template>
        </app-dropdown>

        <app-input-calendar
          required
          name="start_date"
          [showIcon]="true"
          [(date)]="model.start_date"
          [minStartDate]="selectedProject?.start_date"
          [maxDate]="model?.end_date"
          placeholder="Start Date"
        ></app-input-calendar>

        <app-input-calendar
          required
          name="end_date"
          placeholder="Due Date"
          [(date)]="model.end_date"
          [minStartDate]="model?.start_date || selectedProject?.start_date"
          [showIcon]="true"
        ></app-input-calendar>

        <app-floating-textarea
          class="pr-0.5 pl-0.5 h-full"
          label="Additional Details (optional)"
          placeholder="Additional Details (optional)"
          name="details"
          [(model)]="model.description"
          [autoResize]="false"
        ></app-floating-textarea>
      </form>
    </div>
  </ng-scrollbar>

  <div class="flex items-center justify-between ml-auto mb-7 mr-5 mt-4 pl-8 pr-4 gap-4">
    <div *ngIf="model?.id" (click)="deleteActivity()" class="delete-button">Delete</div>
    <div
      (click)="submit()"
      [ngClass]="{ 'bg-color_accent': !model?.id, 'bg-color_secondary': !!model?.id }"
      class="rounded text-white py-1 px-10 font-semibold text-sm cursor-pointer"
    >
      {{ !!model?.id ? 'Update' : 'Save' }}
    </div>
  </div>
  <div></div>
</div>

import { Component, inject } from '@angular/core';
import { SidebarHeaderComponent } from '../sidebar-header/sidebar-header.component';
import { FloatingInputComponent } from '../../inputs/floating-input/floating-input.component';
import { DropdownComponent } from '../../inputs/dropdown/dropdown.component';
import { InputCalendarComponent } from '../../inputs/input-calendar/input-calendar.component';
import { SimpleButtonComponent } from '../../buttons/simple-medium-button/simple-button.component';
import { NgScrollbar } from 'ngx-scrollbar';
import { InputUploadComponent } from '../../input-upload/input-upload.component';
import { NotificationsService } from '../../../services/notifications.service';
import { InteractionBarStateService } from '../../../services/interaction-bar-state.service';
import { NgClass } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
  CREATE_RFI_PAGE,
  CreateRFIModel,
} from 'src/app/pages/webapp/projects/view-project/rfi-listing/rfi.types';

@Component({
  selector: 'app-create-rfi',
  imports: [
    SidebarHeaderComponent,
    FloatingInputComponent,
    DropdownComponent,
    InputCalendarComponent,
    SimpleButtonComponent,
    NgScrollbar,
    InputUploadComponent,
    NgClass,
    FormsModule,
  ],
  standalone: true,
  templateUrl: './create-rfi.component.html',
  styleUrl: './create-rfi.component.scss',
})
export class CreateRfiComponent {
  currentPage = 2;
  MAX_PAGES = 2;
  CREATE_RFI_PAGE = CREATE_RFI_PAGE;

  costImpactOptions = [
    { label: 'Yes', value: 'yes' },
    { label: 'No/TBD', value: 'no' },
  ];

  scheduleImpactOptions = [
    { label: 'Yes', value: 'yes' },
    { label: 'No/TBD', value: 'no' },
  ];

  // todo move into a constants/types file
  model: CreateRFIModel = {
    cost_impact: 'no',
    schedule_impact: 'no',
  };

  private readonly notif = inject(NotificationsService);
  private readonly interactionBar = inject(InteractionBarStateService);

  goToPreviousPage() {
    if (this.currentPage > CREATE_RFI_PAGE.FIRST_PAGE) {
      this.currentPage--;
    }
  }
  goToNextPage() {
    if (this.currentPage < this.MAX_PAGES) {
      this.currentPage++;
    }
  }

  submitForm() {
    this.notif.showSuccess('RFI created successfully (hopefully only)');
    this.interactionBar.close();
  }

  saveDraft() {
    this.notif.showSuccess('Draft saved successfully (hopefully only)');
    this.interactionBar.close();
  }
}

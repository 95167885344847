import { Injectable } from '@angular/core';
import { RestRequestService } from '../restApi/rest-request.service';
import {
  REST_CONTRACTOR,
  REST_CONTRACTOR_AVAILABLE_MANAGERS,
  REST_CONTRACTOR_FAVORITE,
  REST_SCHEDULE_VISITS,
} from '../restApi/RestRoutes';
import { CurrentUserService } from './current-user.service';
import { Router } from '@angular/router';
import { ErrorHandlerRest } from '../restApi/errorHandler-rest';
import { CONTRACTOR_SELECTOR } from '../framework/constants/view.constants';
import { SORT_DIR } from '../dto/contractor.data.source';

@Injectable({
  providedIn: 'root',
})
export class ContractorsApiService extends ErrorHandlerRest {
  constructor(
    private rest: RestRequestService,
    protected user: CurrentUserService,
    protected router: Router,
  ) {
    super(user, router);
  }

  getContractors(
    page = 1,
    type: CONTRACTOR_SELECTOR = CONTRACTOR_SELECTOR.ALL,
    search: string = '',
    sortColumn = '',
    sortDir = '',
  ): Promise<any> {
    return new Promise<any>((res, rej) => {
      let queryString = '?page=' + page;

      if (type !== CONTRACTOR_SELECTOR.ALL) {
        queryString += '&view=' + type;
      }

      if (search && search !== '') {
        queryString += '&search=' + encodeURI(search);
      }

      if (sortDir === SORT_DIR.ASC || sortDir === SORT_DIR.DESC) {
        queryString += '&sort_by=' + sortColumn + '&sort_dir=' + sortDir;
      }

      this.rest.get(REST_CONTRACTOR + queryString).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  getContractor(id): Promise<any> {
    return new Promise<any>((res, rej) => {
      this.rest.get(REST_CONTRACTOR + '/' + id).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  getAvailableManagers(id): Promise<any> {
    return new Promise<any>((res, rej) => {
      this.rest.get(REST_CONTRACTOR_AVAILABLE_MANAGERS + '/' + id).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  updateContractor(data, id = null) {
    return new Promise<any>((res, rej) => {
      this.rest.put(REST_CONTRACTOR + '/' + id, data).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  setFavorite(contracotrId, isFavorite) {
    const body = {
      is_favorite: isFavorite,
    };

    return new Promise<any>((res, rej) => {
      this.rest.put(REST_CONTRACTOR_FAVORITE + '/' + contracotrId, body).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  setFavoriteWithObservable(contracotrId: number, isFavorite: boolean) {
    const body = {
      is_favorite: isFavorite,
    };

    return this.rest.putWithObservable(`${REST_CONTRACTOR_FAVORITE}/${contracotrId}`, body);
  }

  getVisits(): Promise<any> {
    return new Promise<any>((res, rej) => {
      this.rest.get(REST_SCHEDULE_VISITS + '?contractor_id=' + this.user.data.contractor.id).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { CurrentUserService } from '../services/current-user.service';
import { NotificationsService } from '../services/notifications.service';

@Injectable({
  providedIn: 'root',
})
export class CanActivateProjectsContractorGuard {
  constructor(
    private router: Router,
    private user: CurrentUserService,
    private notif: NotificationsService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((res, rej) => {
      const isServiceProvider = this.user.isServiceProvider;
      if (!isServiceProvider) {
        this.router.navigate(['webapp', 'projects', 'manager']);
      }
      res(isServiceProvider);
    });
  }
}

<div *ngIf="!(isSidebarLoading$ | async); else loadingScreen" class="h-full flex flex-col">
  <div class="mr-1">
    <app-commitment-sidebar-title
      [showBackButton]="!isEdit || (currentPage$ | async) !== COMMITMENT_SIDEBAR_PAGE.FIRST_PAGE"
      [title]="isEdit ? 'Edit Miscellaneous Cost' : 'Add Miscellaneous Cost'"
      (goBack)="goBack()"
    ></app-commitment-sidebar-title>
  </div>

  @if ((currentPage$ | async) === COMMITMENT_SIDEBAR_PAGE.FIRST_PAGE) {
    <app-commitment-entry-text entryType="GeneralInfo" text="General Information"></app-commitment-entry-text>
  }
  <ng-scrollbar
    [ngClass]="{ 'first-page': (currentPage$ | async) === COMMITMENT_SIDEBAR_PAGE.FIRST_PAGE }"
    class="std-scrollbar wider-track"
    appearance="compact"
    orientation="vertical"
  >
    <div
      [ngClass]="{ 'mx-7 mt-5': (currentPage$ | async) === COMMITMENT_SIDEBAR_PAGE.FIRST_PAGE }"
      class="scrollable-content mb-5"
    >
      <form
        class="flex flex-col flex-1"
        #firstPageForm="ngForm"
        role="form"
        *ngIf="(currentPage$ | async) === COMMITMENT_SIDEBAR_PAGE.FIRST_PAGE; else secondPage"
        [@fadeInGeneral]
      >
        <div class="flex-1 flex flex-col">
          <app-upload-commitments-sidebar
            [commitmentType]="COMMITMENTS_INTERACTION_BAR_TYPE.DIRECT_COST"
          ></app-upload-commitments-sidebar>

          <app-floating-input
            [required]="true"
            [(model)]="model.vendor"
            label="Vendor Name"
            name="vendor"
            (change)="updateDirectCost()"
          >
          </app-floating-input>

          <div class="two-input-row">
            <app-floating-input
              [required]="true"
              name="title"
              [(model)]="model.title"
              label="Misc Cost Title"
              (change)="updateDirectCost()"
            >
            </app-floating-input>
            <app-dropdown
              [required]="true"
              name="paid_status"
              [(model)]="model.paid_status"
              [options]="paidStatuses"
              (modelChange)="updateDirectCost()"
              label="Misc Cost Status"
            >
              <ng-template let-status #optionContent>
                <div
                  *ngIf="status"
                  [ngClass]="status.value ? 'text-color_primary' : 'text-shade_2'"
                  class="capitalize text-medium"
                >
                  {{ status.text }}
                </div>
              </ng-template>
              <ng-template let-status #selectedContent>
                <div
                  *ngIf="status"
                  [ngClass]="status.value ? 'text-color_primary' : 'text-shade_2'"
                  class="capitalize text-medium"
                >
                  {{ status.text }}
                </div>
              </ng-template>
            </app-dropdown>
          </div>

          <div class="two-input-row">
            <app-input-calendar
              #added_at_calendar
              [required]="true"
              [date]="model.added_at"
              [minStartDate]="projectData?.start_date"
              (dateChanged)="updateAddedDate($event)"
              placeholder="Misc Cost Date"
              name="added_at"
              [ngClass]="{
                'col-span-2': !model.paid_status
              }"
              [showIcon]="true"
            ></app-input-calendar>

            @if (model.paid_status) {
              <app-input-calendar
                #paid_calendar
                [required]="true"
                [date]="model.paid_date"
                [minStartDate]="model?.added_at ?? projectData?.start_date"
                (dateChanged)="updatePaidDate($event)"
                placeholder="Paid Date"
                name="paid_date"
                [showIcon]="true"
              ></app-input-calendar>
            }
          </div>

          <app-floating-textarea
            name="description"
            [(model)]="model.description"
            (modelChange)="updateDirectCost()"
            label="Misc Cost Description"
          >
          </app-floating-textarea>
        </div>
      </form>
      <ng-template #secondPage>
        <div [@fadeInGeneral]>
          <div *ngFor="let entry of spendEntriesData; let index = index; trackBy: trackByIndex">
            <app-spend-entry
              #spendEntries
              [commitmentAddedAt]="model.added_at"
              [model]="entry"
              [projectData]="projectData"
              [index]="index"
              [commitmentType]="COMMITMENTS_INTERACTION_BAR_TYPE.DIRECT_COST"
              [disableRemovedEntry]="spendEntriesData.length === 1"
            ></app-spend-entry>
          </div>
          <div class="horizontal-spacing mt-5 mb-2">
            <app-simple-button
              size="large"
              [wrapperClass]="{
                'border-2   bg-transparent w-full rounded-[3px] ': true,
                'border-color_secondary text-color_secondary': (canAddDirectCostEntries$ | async),
                'border-shade_3 text-shade_3': !(canAddDirectCostEntries$ | async)
              }"
              (click)="addSpendEntry()"
              [disabled]="!(canAddDirectCostEntries$ | async)"
              >Add Spend Entry
            </app-simple-button>
          </div>
        </div>
      </ng-template>
    </div>
  </ng-scrollbar>

  <div class="bottom-container" [ngClass]="{ bordered: (currentPage$ | async) === COMMITMENT_SIDEBAR_PAGE.LAST_PAGE }">
    <span
      *ngIf="(currentPage$ | async) === COMMITMENT_SIDEBAR_PAGE.LAST_PAGE"
      class="text-shade_1 text-base font-bold flex-1 flex"
    >
      <span class="flex-1">Total Misc Cost</span>
      <span class="flex-1 justify-center flex" [ngClass]="{ negative: (totalDirectCost$ | async) < 0 }">
        <span *ngIf="(totalDirectCost$ | async) !== 0">{{ totalDirectCost$ | async | money: 'positiveComa' }}</span>
        <span *ngIf="(totalDirectCost$ | async) === 0">$ {{ totalDirectCost$ | async | number }}</span>
      </span>
    </span>

    <div>
      @if ((currentPage$ | async) === COMMITMENT_SIDEBAR_PAGE.FIRST_PAGE) {
        <app-simple-button
          [wrapperClass]="{
            rounded: true
          }"
          (click)="incrementPage()"
          [disabled]="isLoading"
        >
          <span *ngIf="!isLoading">Next</span>
          <span *ngIf="isLoading" class="icon-refresh animate-spin"></span>
        </app-simple-button>
      } @else {
        <app-simple-button
          (click)="save()"
          [disabled]="isLoading"
          [wrapperClass]="{
            'bg-color_accent rounded': true
          }"
        >
          <span *ngIf="!isLoading">Submit</span>
          <span *ngIf="isLoading" class="icon-refresh animate-spin"></span>
        </app-simple-button>
      }
    </div>
  </div>
</div>

<ng-template #loadingScreen>
  <div class="flex w-full h-full items-center justify-center">
    <div class="notify-msg animate-pulsating-border">
      <div class="icon-logo-box-hex"></div>
    </div>
  </div>
</ng-template>

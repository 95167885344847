import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {
  MatDatepicker,
  MatDatepickerInput,
  MatDatepickerInputEvent,
  MatDatepickerToggle,
} from '@angular/material/datepicker';
import { AbstractControl, ControlContainer, FormsModule, NgForm, NgModel } from '@angular/forms';
import moment from 'moment/moment';
import { MatFormField } from '@angular/material/form-field';
import { DatePipe, NgClass, NgIf } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { CalendarFormatterDirective } from '../../../directives/calendar-formatter.directive';
import { MatInput } from '@angular/material/input';
import { TooltipModule } from 'primeng/tooltip';

/**
 * Role of this component is to use an ngPrime input from which a mat calendar is opened
 */
@Component({
  selector: 'app-input-calendar',
  templateUrl: './input-calendar.component.html',
  styleUrls: ['./input-calendar.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  standalone: true,
  imports: [
    MatFormField,
    MatDatepickerToggle,
    MatDatepicker,
    NgClass,
    FormsModule,
    InputTextModule,
    CalendarFormatterDirective,
    MatDatepickerInput,
    MatInput,
    DatePipe,
    NgIf,
    TooltipModule,
  ],
})
export class InputCalendarComponent {
  @ViewChild('mat_general_date') matGeneralDate: NgModel;

  @Input() name = 'input'; // important to give it a different name, otherwise you will encounter bugs
  @Input() placeholder = 'Select date'; // todo fix placeholder on spend distribution
  @Input() required = false;
  @Input() isDisabled: boolean; // hasCommitments$ | async
  @Input() iconOptions: {
    iconPosition: 'p-input-icon-left' | 'p-input-icon-right' | null;
    icon: string | null;
    showIcon: boolean;
    tooltip?: string;
  } = {
    iconPosition: 'p-input-icon-right',
    icon: 'icon-calendar-box text-color_secondary',
    showIcon: false,
  };
  @Input() set showIcon(value: boolean) {
    this.iconOptions = {
      ...this.iconOptions,
      showIcon: value,
    };
  }
  @Input() set iconTooltip(value: string) {
    this.iconOptions = {
      ...this.iconOptions,
      tooltip: value,
    };
  }

  @Input() set date(value: string) {
    // only an input setter for the component
    if (!value) {
      this.dateModel = null;
      return;
    }
    this.dateModel = this.getDateTimeZoneCorrected(value);
  }

  _dateModel: Date = null;
  set dateModel(value: Date) {
    this._dateModel = value;
    this.calendarOpenDate = value;
  }
  get dateModel() {
    return this._dateModel;
  }

  _minStartDate: Date;
  get minStartDate() {
    return this._minStartDate;
  }
  @Input() set minStartDate(date: string | Date) {
    if (!date) {
      return;
    }

    if (date instanceof Date) {
      this._minStartDate = date;
      return;
    }
    this._minStartDate = this.getDateTimeZoneCorrected(date);
  }

  _maxDate: Date;
  get maxDate(): Date {
    return this._maxDate;
  }
  @Input() set maxDate(date: string | Date) {
    if (!date) {
      return;
    }

    console.log('max date', date);
    if (date instanceof Date) {
      this._maxDate = date;
      return;
    }
    this._maxDate = this.getDateTimeZoneCorrected(date);
  }

  @Input() showLabel = true;
  @Input() topPosition = 'top-[50px]';

  @Output() dateChanged = new EventEmitter<string>();
  @Output() dateChange = new EventEmitter<string>();
  @Output() iconClicked = new EventEmitter<string>();

  calendarOpenDate = null;
  format = 'YYYY-MM-DD';

  onDateChanged($event: MatDatepickerInputEvent<any, any>) {
    // this.setCalendarOpenDate();
    const date = this.getFormattedDateWithoutTimezone($event.value);
    // todo: make only one event
    this.dateChanged.emit(date);
    this.dateChange.emit(date);
    console.log('date changed', date);
  }

  /**
   * Get <strong>JS Date</strong> object with timezone offset added. </br>
   * This is needed because JS Dates do not store timezone info but show date always in user's timezone </br>
   * Eg: dateInput = <i>'2023-09-15T00:00:00.000Z'</i> ,
   * returned value: <i>'Fri Sep 15 2023 00:00:00 GMT-0400 (Eastern Daylight Time)'</i>
   * (with New York timezone) </br>
   * It should return the same date for any timezone. </br>
   * @returns JS Date with timezone offset added
   */
  getDateTimeZoneCorrected(dateInput: string): Date {
    const offsetMinutes = new Date(dateInput).getTimezoneOffset(); // in minutes
    const offsetMs = offsetMinutes * 60 * 1000; // in milliseconds
    // interpret date as UTC and add timezone offset - this is needed for JS Date object
    return new Date(moment.utc(dateInput).unix() * 1000 + offsetMs);
  }

  /**
   * Get date formatted as calendar require to keep timezone </br>
   * Parse date, remove any timezone info
   * so the following dates should become the same date:</br>
   * "Wed Sep 06 2023 00:00:00 GMT+0300 (Eastern European Summer Time)"</br>
   * "2023-09-06T00:00:00.000+03:00"</br>
   * "2023-09-06T00:00:00.000Z"</br>
   */
  getFormattedDateWithoutTimezone(date) {
    // this function gets a lot of different date formats
    // sometimes with timezone, sometimes without it sometimes a string, sometimes a date object

    const stringInput = moment(date).format('YYYY-MM-DD');

    return moment.utc(stringInput).format(this.format);
  }

  getErrorMessage(param: AbstractControl) {
    // ? `Date must be on or after ${moment(param.errors.matDatepickerMin.min).format('MM/DD/YYYY')}`
    return param.hasError('required')
      ? 'Required'
      : param.hasError('matDatepickerMin')
        ? 'Invalid'
        : param.hasError('matDatepickerMax')
          ? 'Invalid'
          : param.hasError('matDatepickerFilter')
            ? 'Invalid date'
            : '';
  }

  validate() {
    this.matGeneralDate.control.markAllAsTouched();
  }

  isValid(): boolean {
    return !!this.matGeneralDate.control.valid;
  }

  onIconClick(event: MouseEvent) {
    event.stopPropagation();
    this.iconClicked.emit();
  }
}

<div [ngClass]="{ 'p-disabled': isDisabled }" [classList]="topPosition" class="relative h-0">
  <mat-form-field class="invisible h-0">
    <input
      [name]="name"
      #mat_general_date="ngModel"
      (click)="picker.open()"
      (dateChange)="onDateChanged($event)"
      [(ngModel)]="dateModel"
      [matDatepicker]="picker"
      [min]="minStartDate"
      [max]="maxDate"
      [required]="required"
      matInput
      readonly
    />
    <!--      [ngModelOptions]="{ standalone: true }"-->
    <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
    <mat-datepicker [startAt]="calendarOpenDate" #picker></mat-datepicker>
  </mat-form-field>
</div>

<span
  (click)="picker.open()"
  [ngClass]="{ 'p-disabled': isDisabled, 'p-float-label my-2 mt-6': showLabel }"
  [classList]="iconOptions.iconPosition"
  class="flex flex-col"
>
  <input
    #ng_general_date="ngModel"
    [(ngModel)]="dateModel"
    [ngClass]="{
      'ng-invalid ng-dirty': mat_general_date.invalid && mat_general_date.touched
    }"
    [required]="required"
    [value]="dateModel | date: 'MM/dd/yyyy'"
    appCalendarFormatter
    class="w-full cursor-pointer"
    [name]="name"
    pInputText
    readonly
    type="text"
  />
  <label *ngIf="showLabel">{{ placeholder }}</label>
  <small
    [ngClass]="{
      hidden: mat_general_date.valid || mat_general_date.untouched
    }"
    class="p-error text-uppercase"
    >{{ getErrorMessage(mat_general_date.control) }}</small
  >
  <i
    (click)="onIconClick($event)"
    *ngIf="iconOptions?.showIcon"
    [classList]="iconOptions?.icon"
    [pTooltip]="iconOptions?.tooltip"
    tooltipStyleClass="input-calendar-tooltip"
    tooltipPosition="bottom"
  ></i>
</span>

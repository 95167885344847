import { Injectable } from '@angular/core';
import { AppState } from '../app-state';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CurrentUserService } from '../../services/current-user.service';
import { commitmentsActions } from './commitments.actions';
import { catchError, filter, map, repeat, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { CommitmentsService } from '../../services/commitments.service';
import { commitmentsSelectors } from './commitments.selectors';
import { InteractionBarStateService } from '../../services/interaction-bar-state.service';
import { NotificationsService } from '../../services/notifications.service';
import { DriveApiService } from '../../services/drive-api.service';
import { from, of } from 'rxjs';
import { ISidebarCommitment } from './commitments.types';
import { PresignedFileUploadService } from '../../services/presigned-file-upload.service';
import { settingsActions } from '../settings/settings.actions';

@Injectable()
export class CommitmentsEffects {
  constructor(
    private store: Store<AppState>,
    private actions: Actions,
    private currentUser: CurrentUserService,
    private apiService: CommitmentsService,
    private interactionBar: InteractionBarStateService,
    private presignedFileUploadService: PresignedFileUploadService,
    private notif: NotificationsService,
    private driveApi: DriveApiService,
  ) {}

  loadAllCommitments$ = createEffect(() => {
    return this.actions.pipe(
      ofType(commitmentsActions.loadAllCommitments),
      switchMap((action) => {
        return this.apiService.getAllCommitments(action.projectId);
      }),
      map((response) => commitmentsActions.allCommitmentsLoaded({ commitments: response })),
      catchError((error) => {
        if (error) {
          console.error(error);
          this.notif.showError(
            error?.error?.message || 'An error occurred while loading commitments',
          );
        } else {
          this.notif.showError("Something went wrong. Couldn't load commitments!");
        }
        return of(commitmentsActions.cancel());
      }),
      repeat(),
    );
  });

  loadCommitmentItems$ = createEffect(() => {
    return this.actions.pipe(
      ofType(commitmentsActions.loadCommitmentItems),
      switchMap((action) => {
        return this.apiService.getCommitmentItems(action.projectId);
      }),
      map((response) => commitmentsActions.commitmentItemsLoaded({ items: response })),
      catchError((error) => {
        if (error) {
          console.error(error);
          this.notif.showError(error);
        }
        return of(commitmentsActions.cancel());
      }),
      repeat(),
    );
  });

  saveContract$ = createEffect(() => {
    return this.actions.pipe(
      ofType(commitmentsActions.saveContract),
      withLatestFrom(
        this.store.select(commitmentsSelectors.getSideBarContract),
        this.store.select(commitmentsSelectors.getProjectData),
      ),
      switchMap(([action, contractData, projectData]) => {
        return this.apiService.addNewContract({ ...contractData, project_id: projectData.id });
      }),
      map((response) => commitmentsActions.contractSuccessfullySaved({ commitment: response })),
      catchError((error) => {
        if (error) {
          this.notif.showError(error);
        } else {
          this.notif.showError("Something went wrong. Couldn't save!");
        }
        return of(commitmentsActions.cancel());
      }),
      repeat(),
    );
  });

  saveChangeOrder$ = createEffect(() => {
    return this.actions.pipe(
      ofType(commitmentsActions.saveChangeOrder),
      withLatestFrom(
        this.store.select(commitmentsSelectors.getSideBarChangeOrder),
        this.store.select(commitmentsSelectors.getProjectData),
      ),
      switchMap(([action, changeOrderData, projectData]) => {
        return this.apiService.addNewChangeOrder({
          ...changeOrderData,
          project_id: projectData.id,
        });
      }),
      map((response) => commitmentsActions.changeOrderSuccessfullySaved({ commitment: response })),
      catchError((error) => {
        if (error) {
          this.notif.showError(error);
        } else {
          this.notif.showError("Something went wrong. Couldn't save!");
        }
        return of(commitmentsActions.cancel());
      }),
      repeat(),
    );
  });

  modifyContract$ = createEffect(() => {
    return this.actions.pipe(
      ofType(commitmentsActions.modifyContract),
      withLatestFrom(
        this.store.select(commitmentsSelectors.getSideBarContract),
        this.store.select(commitmentsSelectors.getProjectData),
      ),
      switchMap(([action, contractData, projectData]) => {
        return this.apiService.modifyExistingContract({
          ...contractData,
          project_id: projectData.id,
        });
      }),
      map((response) => commitmentsActions.contractSuccessfullySaved({ commitment: response })),
      catchError((error) => {
        if (error) {
          this.notif.showError(error);
        } else {
          this.notif.showError("Something went wrong. Couldn't save!");
        }
        return of(commitmentsActions.cancel());
      }),
      repeat(),
    );
  });

  modifyChangeOrder$ = createEffect(() => {
    return this.actions.pipe(
      ofType(commitmentsActions.modifyChangeOrder),
      withLatestFrom(this.store.select(commitmentsSelectors.getSideBarChangeOrder)),
      switchMap(([action, changeOrderData]) => {
        return this.apiService.modifyExistingChangeOrder(changeOrderData);
      }),
      map((response) => commitmentsActions.changeOrderSuccessfullySaved({ commitment: response })),
      catchError((error) => {
        if (error) {
          this.notif.showError(error);
        } else {
          this.notif.showError("Something went wrong. Couldn't save!");
        }
        return of(commitmentsActions.cancel());
      }),
      repeat(),
    );
  });

  saveInvoice$ = createEffect(() => {
    return this.actions.pipe(
      ofType(commitmentsActions.saveInvoice),
      withLatestFrom(
        this.store.select(commitmentsSelectors.getSidebarInvoice),
        this.store.select(commitmentsSelectors.getProjectData),
      ),
      switchMap(([action, invoiceData, projectData]) => {
        return this.apiService.addNewInvoice({ ...invoiceData, project_id: projectData.id });
      }),
      map((response) => commitmentsActions.invoiceSuccessfullySaved({ commitment: response })),
      catchError((error) => {
        if (error) {
          this.notif.showError(error);
        } else {
          this.notif.showError("Something went wrong. Couldn't save!");
        }
        return of(commitmentsActions.cancel());
      }),
      repeat(),
    );
  });

  modifyInvoice$ = createEffect(() => {
    return this.actions.pipe(
      ofType(commitmentsActions.modifyInvoice),
      withLatestFrom(
        this.store.select(commitmentsSelectors.getSidebarInvoice),
        this.store.select(commitmentsSelectors.getProjectData),
      ),
      switchMap(([action, invoiceData, projectData]) => {
        return this.apiService.modifyExistingInvoice({
          ...invoiceData,
          project_id: projectData.id,
        });
      }),
      map((response) => commitmentsActions.invoiceSuccessfullySaved({ commitment: response })),
      catchError((error) => {
        if (error) {
          this.notif.showError(error);
        } else {
          this.notif.showError("Something went wrong. Couldn't save!");
        }
        return of(commitmentsActions.cancel());
      }),
      repeat(),
    );
  });

  saveDirectCost$ = createEffect(() => {
    return this.actions.pipe(
      ofType(commitmentsActions.saveDirectCost),
      withLatestFrom(
        this.store.select(commitmentsSelectors.getSidebarDirectCost),
        this.store.select(commitmentsSelectors.getProjectData),
      ),
      switchMap(([action, directCostData, projectData]) => {
        return this.apiService.addNewDirectCost({ ...directCostData, project_id: projectData.id });
      }),
      map((response) => commitmentsActions.directCostSuccessfullySaved({ commitment: response })),
      catchError((error) => {
        if (error) {
          this.notif.showError(error);
        } else {
          this.notif.showError("Something went wrong. Couldn't save!");
        }
        return of(commitmentsActions.cancel());
      }),
      repeat(),
    );
  });

  modifyDirectCost$ = createEffect(() => {
    return this.actions.pipe(
      ofType(commitmentsActions.modifyDirectCost),
      withLatestFrom(
        this.store.select(commitmentsSelectors.getSidebarDirectCost),
        this.store.select(commitmentsSelectors.getProjectData),
      ),
      switchMap(([action, directCostData, projectData]) => {
        return this.apiService.modifyExistingDirectCost({
          ...directCostData,
          project_id: projectData.id,
        });
      }),
      map((response) => commitmentsActions.directCostSuccessfullySaved({ commitment: response })),
      catchError((error) => {
        if (error) {
          this.notif.showError(error);
        } else {
          this.notif.showError("Something went wrong. Couldn't save!");
        }
        return of(commitmentsActions.cancel());
      }),
      repeat(),
    );
  });

  loadAllCommitmentsSummary$ = createEffect(() => {
    return this.actions.pipe(
      ofType(commitmentsActions.loadAllCommitmentsSummary),
      switchMap((action) => {
        return this.apiService.getAllCommitmentsSummary(action.projectId);
      }),
      map((response) =>
        commitmentsActions.allCommitmentsSummaryLoaded({ commitmentsSummary: response }),
      ),
      catchError((error) => {
        if (error) {
          console.error(error);
          this.notif.showError(
            error?.error?.message || 'An error occurred while loading commitments',
          );
        } else {
          this.notif.showError("Couldn't load commitments summary!");
        }
        return of(commitmentsActions.cancel());
      }),
      repeat(),
    );
  });

  loadDirectCostsSummary$ = createEffect(() => {
    return this.actions.pipe(
      ofType(commitmentsActions.loadDirectCostsSummary),
      filter((_) => !!this?.currentUser?.isManagerLike),
      switchMap((action) => {
        return this.apiService.getDirectCostsSummary(action.projectId);
      }),
      map((response) =>
        commitmentsActions.directCostsSummaryLoaded({ directCostsSummary: response }),
      ),
      catchError((error) => {
        if (error) {
          console.error(error);
          this.notif.showError(
            error?.error?.message || 'An error occurred while loading direct costs summary',
          );
        } else {
          this.notif.showError("Something went wrong. Couldn't load direct costs summary!");
        }
        return of(commitmentsActions.cancel());
      }),
      repeat(),
    );
  });

  loadCommitmentById$ = createEffect(() => {
    return this.actions.pipe(
      ofType(commitmentsActions.loadCommitmentById),
      switchMap((action) => {
        return this.apiService.getCommitmentById(action.id);
      }),
      map((response) => commitmentsActions.commitmentByIdLoaded({ commitment: response })),
      catchError((error) => {
        if (error) {
          console.error(error);
          this.notif.showError(
            error?.error?.message || 'An error occurred while loading commitment',
          );
        } else {
          this.notif.showError("Something went wrong. Couldn't load commitment!");
        }
        return of(commitmentsActions.cancel());
      }),
      repeat(),
    );
  });

  loadSidebarCommitmentById$ = createEffect(() => {
    return this.actions.pipe(
      ofType(commitmentsActions.loadSidebarCommitment),
      withLatestFrom(this.store.select(commitmentsSelectors.getCommitmentsState)),
      tap(([commitment, state]) => {
        this.store.dispatch(commitmentsActions.sidebarCommitmentLoading({ isLoading: true }));
        if (!state.allCommitmentsSummary && commitment.projectId) {
          // if the summary is not loaded (the sidebar is going to be opened externally), load it
          this.store.dispatch(
            commitmentsActions.loadAllCommitmentsSummary({ projectId: commitment.projectId }),
          );
        }
        return commitment;
      }),
      switchMap(([action, state]) => {
        return this.apiService.getCommitmentById(action.id);
      }),
      map((response) => {
        if (response.contract_id) {
          // todo: to be discussed, sometimes selectedContract and selectedContractSummary are the same but used in different places
          // does it make sense to have a duplicate of the same data or will it over time change? duplicate is safe for now
          this.store.dispatch(
            commitmentsActions.loadSidebarSelectedContractSummary({
              contractId: response.contract_id,
            }),
          );
        }
        return commitmentsActions.sidebarCommitmentLoaded({ sidebarCommitment: response });
      }),
      catchError((error) => {
        if (error) {
          console.error(error);
          this.notif.showError(
            error?.error?.message || 'An error occurred while loading commitment',
          );
        } else {
          this.notif.showError("Something went wrong. Couldn't load commitment!");
        }
        return of(commitmentsActions.cancel());
      }),
      repeat(),
    );
  });

  loadSidebarContractSummaryById = createEffect(() => {
    return this.actions.pipe(
      ofType(commitmentsActions.loadSidebarSelectedContractSummary),
      switchMap((action) => {
        return this.apiService.getContractById(action.contractId);
      }),
      map((response) =>
        commitmentsActions.sidebarSelectedContractSummaryLoaded({ contractDetails: response }),
      ),
      catchError((error) => {
        if (error) {
          console.error(error);
          this.notif.showError(error);
        } else {
          this.notif.showError("Something went wrong. Couldn't load commitment!");
        }
        return of(commitmentsActions.cancel());
      }),
      repeat(),
    );
  });

  loadContractById$ = createEffect(() => {
    return this.actions.pipe(
      ofType(commitmentsActions.loadContractById),
      switchMap((action) => {
        return this.apiService.getContractById(action.id);
      }),
      map((response) => commitmentsActions.contractByIdLoaded({ contract: response })),
      catchError((error) => {
        if (error) {
          console.error(error);
          this.notif.showError(error);
        } else {
          this.notif.showError("Something went wrong. Couldn't load commitment!");
        }
        return of(commitmentsActions.cancel());
      }),
      repeat(),
    );
  });

  reloadCommitments$ = createEffect(() => {
    // upload the selected files after the commitment is saved
    return this.actions.pipe(
      ofType(
        commitmentsActions.contractSuccessfullySaved,
        commitmentsActions.changeOrderSuccessfullySaved,
        commitmentsActions.invoiceSuccessfullySaved,
        commitmentsActions.directCostSuccessfullySaved,
        commitmentsActions.commitmentRemoved,
      ),
      withLatestFrom(this.store.select(commitmentsSelectors.getSelectedContract)),
      map(([action, selectedContract]) => {
        if (selectedContract) {
          this.store.dispatch(
            commitmentsActions.loadContractById({ id: selectedContract.contract.id }),
          );
        }

        if (action.type === commitmentsActions.directCostSuccessfullySaved.type) {
          this.store.dispatch(
            commitmentsActions.loadDirectCostsSummary({ projectId: action.commitment.project_id }),
          );
        }

        if (action.type === commitmentsActions.commitmentRemoved.type) {
          return commitmentsActions.loadAllCommitmentsSummary({
            projectId: action.projectId,
          });
        }

        return commitmentsActions.loadAllCommitmentsSummary({
          projectId: action.commitment.project_id,
        });
      }),
      catchError((error) => {
        if (error) {
          console.error(error);
          this.notif.showError(error);
        } else {
          this.notif.showError("Something went wrong. Couldn't reload commitment!");
        }
        return of(commitmentsActions.cancel());
      }),
      repeat(),
    );
  });

  removeCommitment$ = createEffect(() => {
    return this.actions.pipe(
      ofType(commitmentsActions.removeCommitment),
      switchMap((action) => {
        return from(this.apiService.deleteCommitment(action.id)).pipe(
          map((response) => {
            this.apiService.refreshData();
            this.notif.showSuccess('Commitment removed');
            return commitmentsActions.commitmentRemoved({
              id: action.id,
              projectId: action.projectId,
            });
          }),
        );
      }),
    );
  });

  projectChanged$ = createEffect(() => {
    return this.actions.pipe(
      ofType(commitmentsActions.projectChanged),
      map((action) => {
        this.store.dispatch(commitmentsActions.loadAllCommitments({ projectId: action.projectId }));
        this.store.dispatch(
          settingsActions.loadBudgetTagTemplatesFromCommitments({ projectId: action.projectId }),
        );
        this.store.dispatch(
          commitmentsActions.loadCommitmentItems({ projectId: action.projectId }),
        );
        this.store.dispatch(
          commitmentsActions.loadAllCommitmentsSummary({ projectId: action.projectId }),
        );
        this.store.dispatch(
          commitmentsActions.loadDirectCostsSummary({ projectId: action.projectId }),
        );
        return commitmentsActions.cancel();
      }),
    );
  });

  uploadFiles$ = createEffect(() => {
    // upload the selected files after the commitment is saved
    return this.actions.pipe(
      ofType(
        commitmentsActions.contractSuccessfullySaved,
        commitmentsActions.changeOrderSuccessfullySaved,
        commitmentsActions.invoiceSuccessfullySaved,
        commitmentsActions.directCostSuccessfullySaved,
      ),
      withLatestFrom(
        this.store.select(commitmentsSelectors.getSideBarContract),
        this.store.select(commitmentsSelectors.getSideBarChangeOrder),
        this.store.select(commitmentsSelectors.getSidebarInvoice),
        this.store.select(commitmentsSelectors.getSidebarDirectCost),
        this.store.select(commitmentsSelectors.getSelectedContract),
        (action, contract, changeOrder, invoice, directCost, selectedContract) => {
          switch (action.type) {
            case commitmentsActions.contractSuccessfullySaved.type: {
              return [action, contract, selectedContract?.contract?.id];
            }
            case commitmentsActions.changeOrderSuccessfullySaved.type: {
              return [action, changeOrder, selectedContract?.contract?.id];
            }
            case commitmentsActions.invoiceSuccessfullySaved.type: {
              return [action, invoice, selectedContract?.contract?.id];
            }
            case commitmentsActions.directCostSuccessfullySaved.type: {
              return [action, directCost, null];
            }
            default: {
              console.warn('not implemented');
              return [action, null];
            }
          }
        },
      ),
      // todo: check again on action type
      map(
        ([action, sidebarCommitment, selectedContractId]: [
          action: any,
          sidebarCommitment: ISidebarCommitment,
          selectedContractId: number,
        ]) => {
          if (!sidebarCommitment) {
            return commitmentsActions.cancel();
          }

          this.uploadFiles(
            action.commitment.id,
            action.commitment.project_id,
            sidebarCommitment,
          ).then(
            (uploadedSuccessfully) => {
              if (selectedContractId) {
                this.store.dispatch(
                  commitmentsActions.loadCommitmentById({ id: selectedContractId }),
                );
              } else {
                this.store.dispatch(
                  commitmentsActions.loadDirectCostsSummary({
                    projectId: action.commitment.project_id,
                  }),
                );
              }
              return commitmentsActions.cancel();
            },
            (error) => {
              return commitmentsActions.cancel();
            },
          );

          return commitmentsActions.cancel();
        },
      ),
      catchError((error) => {
        // I suspect this catchError code won't be ever executed, consider deleting it
        if (error) {
          console.error(error);
          this.notif.showError(error);
        } else {
          this.notif.showError("Something went wrong. Couldn't upload file!");
        }
        return of(commitmentsActions.cancel());
      }),
      repeat(),
    );
  });

  private async removeFiles(fileIds: number[]) {
    if (!fileIds || fileIds.length === 0) {
      return;
    }

    this.notif.showLoading('Deleting files...');
    const deletePromises = fileIds.map((id) => this.driveApi.deleteFile(id));
    try {
      await Promise.all(deletePromises);
      this.notif.close();
    } catch (error) {
      if (error) {
        setTimeout(() => {
          this.notif.showError('Could not delete one or more files.');
        }, 1000);
      }
    }
  }

  private async uploadFiles(
    commitmentId: number,
    projectId: number,
    sidebarCommitment: ISidebarCommitment,
  ): Promise<boolean> {
    await this.removeFiles(sidebarCommitment.removeFileIds);

    // const options = this.getFileUploadOptions(commitmentId, projectId);
    const filesToUpload = sidebarCommitment?.files?.filter(
      (file: File & { id: null }) => !file?.id,
    );

    if (!filesToUpload || filesToUpload?.length === 0) {
      this.interactionBar.close();
      return new Promise((resolve) => resolve(true));
    }

    const metaData = {
      commitment_id: commitmentId,
      project_id: projectId,
    };

    return new Promise((resolve, reject) => {
      this.presignedFileUploadService
        .uploadMultipleFilesToS3(
          filesToUpload,
          filesToUpload.map((_) => metaData),
        )
        .subscribe((uploadStatuses) => {
          this.interactionBar.close();
          setTimeout(() => {
            // this.reloadSelectedContract(commitmentId, sidebarCommitment.type, projectId);
            if (uploadStatuses.every((status) => !status?.error)) {
              this.notif.showSuccess('Files successfully uploaded.');
              resolve(true);
              return;
            }
            const firstError = uploadStatuses.find((status) => !!status?.error);
            this.notif.showError(
              `Error during upload: ${firstError?.error?.message ?? 'Could not upload some files.'}`,
            );

            reject(false);
          }, 100);
        });
    });
  }
}

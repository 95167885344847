<div class="sidebar-header-wrapper">
  <div (click)="backButtonClicked.emit()" class="small-title flex" [ngClass]="{ 'cursor-pointer': showBackButton }">
    <app-back-button-box *ngIf="showBackButton"></app-back-button-box>
    <ng-content select="[smallTitle]"></ng-content>
  </div>

  <div class="main-title">
    <ng-content select="[mainTitle]"></ng-content>
  </div>
</div>

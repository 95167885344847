import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { CurrentUserService } from '../services/current-user.service';

/**
 * Redirect to contractor page if not a manager or GC
 * @param next
 * @param state
 */
export const isManagerLike: CanActivateFn = async (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const router = inject(Router);
  const user = inject(CurrentUserService);

  if (!user.isManagerLike) {
    await router.navigate(['webapp', 'projects', 'contractor']);
  }
  return true;
};

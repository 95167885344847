import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';
import { BackButtonBoxComponent } from '../../back-button-box/back-button-box.component';

@Component({
  selector: 'app-sidebar-header',
  standalone: true,
  imports: [NgIf, BackButtonBoxComponent, NgClass],
  templateUrl: './sidebar-header.component.html',
  styleUrl: './sidebar-header.component.scss',
})
export class SidebarHeaderComponent {
  @Input() showBackButton!: boolean;
  @Output() backButtonClicked = new EventEmitter<void>();
}

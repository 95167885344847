import { Pipe, PipeTransform } from '@angular/core';
import { INTERACTION_BAR_STATES } from '../../framework/constants/interaction-bar.constants';

/**
 * handle drawer position, meaning where is the interaction-bar going to show up
 * start = left, end = right
 */
@Pipe({
  name: 'handleInteractionBarPosition',
  standalone: true,
})
export class HandleInteractionBarPositionPipe implements PipeTransform {
  drawerPosition: 'start' | 'end' = 'end';
  openOnLeft = new Set<string>([
    INTERACTION_BAR_STATES.MESSAGES,
    INTERACTION_BAR_STATES.NOTIFICATIONS,
    INTERACTION_BAR_STATES.ACTIVITY_SIDEBAR,
    INTERACTION_BAR_STATES.SETUP_PROJECT_MANAGER,
    INTERACTION_BAR_STATES.SETUP_PROJECT_GC,
  ]);
  transform(drawerState: INTERACTION_BAR_STATES | string): 'start' | 'end' {
    if (
      drawerState === 'closed' ||
      drawerState === INTERACTION_BAR_STATES.CLOSE ||
      (drawerState === INTERACTION_BAR_STATES.SUCCESS_CLOSED &&
        drawerState !== INTERACTION_BAR_STATES.SUCCESS_CLOSED)
    ) {
      return this.drawerPosition;
    }

    if (this.openOnLeft.has(drawerState)) {
      this.drawerPosition = 'start';
      return 'start';
    }
    this.drawerPosition = 'end';
    return 'end';
  }
}

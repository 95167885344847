import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { CurrentUserService } from '../services/current-user.service';

@Injectable({
  providedIn: 'root',
})
export class CanActivateProjectsManagerGuard {
  constructor(
    private router: Router,
    private user: CurrentUserService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((res, rej) => {
      const isManagerLike = this.user.isManagerLike;
      if (!isManagerLike) {
        this.router.navigate(['webapp', 'projects', 'contractor']);
      }

      res(isManagerLike);
    });
  }
}
